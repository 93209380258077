// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-container {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 300px;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .navbar {
    height: 80px;
    background-color: #fff;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/Layout/layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,gBAAgB;EAClB","sourcesContent":[".layout-container {\n    display: flex;\n    height: 100vh;\n  }\n  \n  .sidebar {\n    width: 300px;\n    height: 100vh;\n    background-color: #f8f9fa;\n  }\n  \n  .content-container {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .navbar {\n    height: 80px;\n    background-color: #fff;\n  }\n  \n  .main-content {\n    flex-grow: 1;\n    padding: 20px;\n    overflow-y: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

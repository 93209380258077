import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Loader from './components/loader';
import { Pathname } from './pathname';
import Layout from './Layout';

const Login = lazy(() => import('./views/before-auth/login/index'));
const Dashboard = lazy(() => import('./views/after-auth/dashboard/'));
const Customer = lazy(() => import('./views/after-auth/customer/'));
const Insurances = lazy(() => import('./views/after-auth/Insurances'));
const Users = lazy(() => import('./views/after-auth/users'));
const Payments = lazy(() => import('./views/after-auth/payments'));
const NotFound = lazy(() => import('./views/404'));
const Claims = lazy(() => import('./views/after-auth/claims'));
const Notification = lazy(() => import('./views/after-auth/notification'));
const Support = lazy(() => import('./views/after-auth/support'));
const CustomerProfile = lazy(() => import('./components/Customers/CustomerProfile'))

const router = createBrowserRouter([
    {
        path: Pathname.LOGIN,
        element: <Login />,
    },
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: Pathname.DASHBOARD,
                element: <Dashboard />,
            },
            {
                path: Pathname.USERS,
                element: <Users />,
            },
            {
                path: Pathname.CUSTOMERS,
                element: <Customer />,
                children: [
                    {
                        path: 'cid/:id',
                        element: <CustomerProfile />,
                    },
                ],
            },
            {
                path: Pathname.PAYMENTS,
                element: <Payments />,
            },
            {
                path: Pathname.INSURANCES,
                element: <Insurances />,
            },
            {
                path: Pathname.CLAIMS,
                element: <Claims />,
            },
            {
                path: Pathname.NOTIFICATION,
                element: <Notification />
            },
            {
                path: Pathname.HELPANDSUPPORT,
                element: <Support />
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
]);

export function App() {
	return (
		<Suspense fallback={<Loader />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default App;

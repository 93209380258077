import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: 'ffffff',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
        }}
    >
        <CircularProgress sx={{ color: '#F7553D', width: 100, height: 100 }} thickness={6}/>
    </Box>
);

export default Loader;

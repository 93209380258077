import dashboardIcon from '../Icons/dashboard.svg';
import usersIcon from '../Icons/users.svg';
import paymentsIcon from '../Icons/payments.svg';
import insurancesIcon from '../Icons/Insurances.svg';
import customerIcon from '../Icons/customer.svg';
import claimsIcon from '../Icons/claims.svg';
import notificationIcon from '../Icons/notification.svg';
import helpsupportIcon from '../Icons/help&support.svg';
import eyeSlashIcon from '../Icons/eye-slash.svg';
import vehicleIcon from '../Icons/vehicle.svg';
import repeateIcon from '../Icons/repeate.svg';
import acticeDashboard from '../Icons/acticeDashboard.svg';
import activeUsers from '../Icons/activeUsers.svg';
import activePayments from '../Icons/activePayments.svg';
import activeInsurances from '../Icons/activeInsurances.svg';
import activeCustomers from '../Icons/activeCustomers.svg';
import activeClaims from '../Icons/activeClaims.svg';
import activeNotification from '../Icons/activeNotification.svg';
import activeHelpSupport from '../Icons/activeHelp&Support.svg';
import NoDataIcon from '../Icons/emptyImage.svg';
import closeCircle from '../Icons/closeCircle.svg';
import moreCircle from '../Icons/moreCircle.svg';
import logout from '../Icons/logout.svg';
import closeIcon from '../Icons/close.svg';
import personalcard from '../Icons/personalcard.svg';
import newTabIcon from '../Icons/newTab.svg';
import linkIcon from '../Icons/link.svg';
import uploadIcon from '../Icons/uploadIcon.svg';
import editLinkIcon from '../Icons/editLink.svg';
import deleteLinkIcon from '../Icons/deleteLink.svg';
import editInsuranceIcon from '../Icons/editInsurance.svg';
import filterDateIcon from '../Icons/filterDate.svg';
import importantIcon from '../Icons/important.svg';
import crossIcon from '../Icons/crossIcon.svg';
import arrowRightIcon from '../Icons/arrowRight.svg';
import colorEditIcon from '../Icons/colorEdit.svg'
import key from '../Icons/key.svg';

export {
	dashboardIcon,
	usersIcon,
	paymentsIcon,
	insurancesIcon,
	customerIcon,
	claimsIcon,
	notificationIcon,
	helpsupportIcon,
	eyeSlashIcon,
	vehicleIcon,
	repeateIcon,
	acticeDashboard,
	activeUsers,
	activePayments,
	activeInsurances,
	activeCustomers,
	activeClaims,
	activeNotification,
	activeHelpSupport,
	NoDataIcon,
	closeCircle,
	moreCircle,
	logout,
	closeIcon,
	newTabIcon,
	personalcard,
	linkIcon,
	uploadIcon,
	editLinkIcon,
	deleteLinkIcon,
	editInsuranceIcon,
	filterDateIcon,
	importantIcon,
	crossIcon,
	arrowRightIcon,
	colorEditIcon,
	key
};

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Dropdown, Button, Modal, Form } from 'react-bootstrap';
import { notificationIcon, key, logout, closeIcon } from '../../../assets/Icons';
import { Formik, Field } from 'formik';
import './navbar.css';
const CustomNavbar = ({ activePage }) => {
	const [showSignOut, setShowSignOut] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState(false);

	const handleShow = () => {
		setShowChangePassword(true);
		setShowSignOut(false);
	};
	const handleClose = () => setShowChangePassword(false);

	const toggleSignOut = () => {
		setShowSignOut(true);
	};
	const toggleNotification = () => setShowNotification((prevState) => !prevState);

	const closeSignOut = () => setShowSignOut(false);
	const closeNotification = () => setShowNotification(false);

	return (
		<Navbar className="shadow-sm p-1 d-flex justify-content-between">
			<Container>
				<Navbar.Brand href="#" className="navbar-brand-custom">
					{activePage}
				</Navbar.Brand>
				<Nav className="d-flex align-items-center">
					<Nav.Link className="position-relative" onClick={toggleNotification} onBlur={closeNotification}>
						<img src={notificationIcon} alt="Notifications" />
						{showNotification && (
							<Dropdown
								align="end"
								className="position-absolute"
								style={{ top: '100%', right: '0', width: '300px' }}
							>
								<Dropdown.Menu
									show
									style={{ maxHeight: '600px', overflowY: 'auto', padding: '0.5rem' }}
								>
									<div className="d-flex justify-content-between align-items-center p-2">
										<h5 className="mb-0" style={{ fontSize: '1.5rem' }}>
											Notifications
										</h5>
										<Dropdown.Item className="p-0" style={{ width: 'auto' }}>
											<Nav.Link
												variant="link"
												style={{ fontWeight: 'bold', color: '#F56F10', fontSize: '0.875rem' }}
											>
												Mark all as read
											</Nav.Link>
										</Dropdown.Item>
									</div>
									<Dropdown.Divider />

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>
									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>

									<Dropdown.Item
										className="text-truncate"
										style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
									>
										<div className="d-flex flex-column">
											<div>
												<span className="fw-bold">Aditya Patel</span> submitted a claim for{' '}
												<span className="fw-bold">Oriental Insurance</span>.
											</div>
										</div>
										<p>5h ago</p>
									</Dropdown.Item>
									<Dropdown.Divider />
									{/* Repeat for more notifications */}
								</Dropdown.Menu>
							</Dropdown>
						)}
					</Nav.Link>

					<Nav.Link className="mt-2 mr-10" onClick={toggleSignOut} onBlur={closeSignOut}>
						<div className="border-auto rounded d-flex gap-2">
							<div style={{ borderLeft: '1px solid #E2E8F0' }}></div>
							<div
								className="p-2 mb-3 fw-bold border rounded"
								style={{ background: '#FEF0E7', color: '#F7893B' }}
							>
								PS
							</div>
							<div className="">
								<span className="fw-bold">Prasun Singh</span>
								<p className="">Admin</p>

								<Dropdown show={showSignOut}>
									<Dropdown.Menu
										align="end"
										style={{
											position: 'absolute',
											top: '100%',
											right: '0',
											borderRadius: '4px',
											zIndex: '1',
										}}
									>
										<Dropdown.Item className="d-flex align-items-center gap-2" onBlur={closeSignOut} onSelect={handleShow}>
											<img src={key} alt="Change Password Icon" />
											<span role="button" style={{ cursor: 'pointer' }}>
												Change Password
											</span>
										</Dropdown.Item>

										<Dropdown.Item
											onClick={() => console.log('Sign Out')}
											className="d-flex align-items-center gap-2"
											style={{
												color: '#F43F5E',
											}}
										>
											<img src={logout} alt="Sign Out Icon" />
											<span>Sign Out</span>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</Nav.Link>
				</Nav>
			</Container>
			
			<Modal show={showChangePassword} onHide={handleClose} backdrop={true} centered>
				<Modal.Header className="d-flex justify-content-between">
					<div>
						<Modal.Title>Change Password</Modal.Title>
						<span>prasun.s@gmail.com</span>
					</div>
					<Button variant="link" onClick={handleClose} className="p-0">
						<img src={closeIcon} alt="" />
					</Button>
				</Modal.Header>
				<Formik
					initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
					onSubmit={(values) => {
						console.log(values);
						handleClose();
					}}
				>
					{({ handleSubmit, errors, touched }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Modal.Body>
								<Form.Group className="mb-3" controlId="formOldPassword">
									<Form.Label style={{ color: '#475569' }}>
										Old Password
										<span style={{ color: 'red' }}>*</span>
									</Form.Label>
									<Field
										style={{
											height: '46px',
											padding: '10px',
											border: 'solid 1px #CBD5E1',
										}}
										name="oldPassword"
										type="password"
										as={Form.Control}
										placeholder="Enter old password"
										isInvalid={touched.oldPassword && errors.oldPassword}
										fullWidth
									/>
									<Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formNewPassword">
									<Form.Label>
										New Password
										<span style={{ color: 'red' }}>*</span>
									</Form.Label>
									<Field
										style={{
											height: '46px',
											padding: '10px',
											border: 'solid 1px #CBD5E1',
										}}
										name="newPassword"
										type="password"
										as={Form.Control}
										placeholder="Enter new password"
										isInvalid={touched.newPassword && errors.newPassword}
									/>
									<Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formConfirmPassword">
									<Form.Label>
										Confirm Password
										<span style={{ color: 'red' }}>*</span>
									</Form.Label>
									<Field
										style={{
											height: '46px',
											padding: '10px',
											border: 'solid 1px #CBD5E1',
										}}
										name="confirmPassword"
										type="password"
										as={Form.Control}
										placeholder="Confirm new password"
										isInvalid={touched.confirmPassword && errors.confirmPassword}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.confirmPassword}
									</Form.Control.Feedback>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="secondary"
									onClick={handleClose}
									style={{
										borderColor: 'black',
										color: 'black',
										background: 'white',
										borderWidth: '1px',
										width: '8rem',
									}}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									variant="warning"
									style={{
										color: 'white',
										background: '#F56F10',
										borderWidth: '1px',
										width: '8rem',
									}}
								>
									Continue
								</Button>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
		</Navbar>
	);
};

export default CustomNavbar;

export let Pathname = {
	LOGIN: '/',
	DASHBOARD: '/dashboard',
	USERS : '/users',
	PAYMENTS : '/payments',
	INSURANCES : '/insurances',
	CUSTOMERS : '/customers',
	CLAIMS : '/claims',
	NOTIFICATION : '/notification',
	HELPANDSUPPORT: '/help-Support'
  };
  
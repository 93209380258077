// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-border {
    border-bottom: 2px solid rgb(224, 222, 222)!important;
}`, "",{"version":3,"sources":["webpack://./src/components/CommonComponents/Navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI,qDAAqD;AACzD","sourcesContent":[".custom-border {\n    border-bottom: 2px solid rgb(224, 222, 222)!important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from 'react';
import Navbar from '../components/CommonComponents/Navbar';
import Sidebar from '../components/CommonComponents/Sidebar';
import './layout.css';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const [activePage, setActivePage] = useState('Dashboard');
  return (
    <div className="layout-container">
      <Sidebar setActivePage={setActivePage} />
      <div className="content-container">
        <Navbar activePage={activePage} />
        <main className="main-content">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;

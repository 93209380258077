import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { closeIcon } from '../../assets/Icons';

const ChangePasswordModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} backdrop={true} centered>
            <Modal.Header className="d-flex justify-content-between">
                <div>
                    <Modal.Title>Change Password</Modal.Title>
                    <span>prasun.s@gmail.com</span>
                </div>
                <Button variant="link" onClick={handleClose} className="p-0">
                    <img src={closeIcon} alt="Close Icon" />
                </Button>
            </Modal.Header>
            <Formik
                initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
                onSubmit={(values) => {
                    console.log(values);
                    handleClose();
                }}
            >
                {({ handleSubmit, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="formOldPassword">
                                <Form.Label style={{ color: '#475569' }}>
                                    Old Password <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Field
                                    style={{
                                        height: '46px',
                                        padding: '10px',
                                        border: 'solid 1px #CBD5E1',
                                    }}
                                    name="oldPassword"
                                    type="password"
                                    as={Form.Control}
                                    placeholder="Enter old password"
                                    isInvalid={touched.oldPassword && errors.oldPassword}
                                    fullWidth
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.oldPassword}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formNewPassword">
                                <Form.Label>
                                    New Password <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Field
                                    style={{
                                        height: '46px',
                                        padding: '10px',
                                        border: 'solid 1px #CBD5E1',
                                    }}
                                    name="newPassword"
                                    type="password"
                                    as={Form.Control}
                                    placeholder="Enter new password"
                                    isInvalid={touched.newPassword && errors.newPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.newPassword}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formConfirmPassword">
                                <Form.Label>
                                    Confirm Password <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Field
                                    style={{
                                        height: '46px',
                                        padding: '10px',
                                        border: 'solid 1px #CBD5E1',
                                    }}
                                    name="confirmPassword"
                                    type="password"
                                    as={Form.Control}
                                    placeholder="Confirm new password"
                                    isInvalid={touched.confirmPassword && errors.confirmPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                style={{
                                    borderColor: 'black',
                                    color: 'black',
                                    background: 'white',
                                    borderWidth: '1px',
                                    width: '8rem',
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="warning"
                                style={{
                                    color: 'white',
                                    background: '#F56F10',
                                    borderWidth: '1px',
                                    width: '8rem',
                                }}
                            >
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ChangePasswordModal;

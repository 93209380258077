import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../../assets/Images/logo.png'
import './Sidebar.css';
import { Pathname } from '../../../pathname';
import {
	dashboardIcon,
	usersIcon,
	claimsIcon,
	paymentsIcon,
	customerIcon,
	insurancesIcon,
	notificationIcon,
	helpsupportIcon,
	acticeDashboard,
	activeUsers,
	activePayments,
	activeInsurances,
	activeCustomers,
	activeClaims,
	activeNotification,
	activeHelpSupport,
} from '../../../assets/Icons';

export const Sidebar = ({ setActivePage }) => {
	const [activeLink, setActiveLink] = useState('');
	const location = useLocation();

	const path = location.pathname.slice(1);
	const formattedPath = path.charAt(0).toUpperCase() + path.slice(1).toLowerCase();

	console.log(formattedPath);

	const handleLinkClick = (link) => {
		setActivePage(link);
		setActiveLink(link);
	};

	useEffect(() => {
		console.log('yyug');

		handleLinkClick(formattedPath);
	}, [formattedPath]);

	return (
		<div className="sidebar border d-flex flex-column align-items-center pb-2">
			<div className="logo-section d-flex align-items-center">
				<img src={logo} alt="Logo" className="logo-image ms-2" />
				<span className="logo-text ms-2">Shield Insurance</span>
			</div>

			<nav className="nav flex-column mt-2">
				<div
					className="nav-item"
					onClick={() => handleLinkClick('Dashboard')}
					style={{
						backgroundColor: activeLink === 'Dashboard' ? 'white' : 'transparent',
						border: activeLink === 'Dashboard' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Dashboard' ? '8px' : 'initial',
					}}
				>
					<img src={activeLink === 'Dashboard' ? acticeDashboard : dashboardIcon} alt="Dashboard Icon" />
					<NavLink
						to={Pathname.DASHBOARD}
						className="nav-link"
						style={{
							border: '#FBC49D',
							color: activeLink === 'Dashboard' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Dashboard' ? 'bold' : 'normal',
						}}
					>
						Dashboard{' '}
					</NavLink>
				</div>

				<div
					className={`nav-item ${activeLink === 'Users' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Users')}
					style={{
						backgroundColor: activeLink === 'Users' ? 'white' : 'transparent',
						border: activeLink === 'Users' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Users' ? '8px' : 'initial',
					}}
				>
					<img src={activeLink === 'Users' ? activeUsers : usersIcon} alt="Users Icon" />
					<NavLink
						to={Pathname.USERS}
						className="nav-link"
						style={{
							color: activeLink === 'Users' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Users' ? 'bold' : 'normal',
						}}
					>
						Users
					</NavLink>
				</div>

				<div
					className={`nav-item ${activeLink === 'Payments' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Payments')}
					style={{
						backgroundColor: activeLink === 'Payments' ? 'white' : 'transparent',
						border: activeLink === 'Payments' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Payments' ? '8px' : 'initial',
					}}
				>
					<img src={activeLink === 'Payments' ? activePayments : paymentsIcon} alt="Payments Icon" />
					<NavLink
						className="nav-link"
						to={Pathname.PAYMENTS}
						style={{
							color: activeLink === 'Payments' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Payments' ? 'bold' : 'normal',
						}}
					>
						Payments
					</NavLink>
				</div>

				<div
					className={`nav-item ${activeLink === 'Insurances' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Insurances')}
					style={{
						backgroundColor: activeLink === 'Insurances' ? 'white' : 'transparent',
						border: activeLink === 'Insurances' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Insurances' ? '8px' : 'initial',
					}}
				>
					<img src={activeLink === 'Insurances' ? activeInsurances : insurancesIcon} alt="Insurances Icon" />
					<NavLink
						className="nav-link"
						to={Pathname.INSURANCES}
						style={{
							color: activeLink === 'Insurances' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Insurances' ? 'bold' : 'normal',
						}}
					>
						Insurances
					</NavLink>
				</div>

				<div
					className={`nav-item ${activeLink === 'Customers' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Customers')}
					style={{
						backgroundColor: activeLink === 'Customers' ? 'white' : 'transparent',
						border: activeLink === 'Customers' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Customers' ? '8px' : 'initial',
					}}
				>
					<img src={activeLink === 'Customers' ? activeCustomers : customerIcon} alt="Customers Icon" />
					<NavLink
						className="nav-link"
						to={Pathname.CUSTOMERS}
						style={{
							color: activeLink === 'Customers' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Customers' ? 'bold' : 'normal',
						}}
					>
						Customers
					</NavLink>
				</div>

				<div
					className={`nav-item ${activeLink === 'Claims' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Claims')}
					style={{
						backgroundColor: activeLink === 'Claims' ? 'white' : 'transparent',
						border: activeLink === 'Claims' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Claims' ? '8px' : 'initial',
					}}
				>
					<img src={activeLink === 'Claims' ? activeClaims : claimsIcon} alt="Claims Icon" />
					<NavLink
						className="nav-link"
						to={Pathname.CLAIMS}
						style={{
							color: activeLink === 'Claims' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Claims' ? 'bold' : 'normal',
						}}
					>
						Claims
					</NavLink>
				</div>

				<div
					className={`nav-item ${activeLink === 'Notification' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Notification')}
					style={{
						backgroundColor: activeLink === 'Notification' ? 'white' : 'transparent',
						border: activeLink === 'Notification' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Notification' ? '8px' : 'initial',
					}}
				>
					<img
						src={activeLink === 'Notification' ? activeNotification : notificationIcon}
						alt="Notification Icon"
					/>
					<NavLink
						className="nav-link"
						to={Pathname.NOTIFICATION}
						style={{
							color: activeLink === 'Notification' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Notification' ? 'bold' : 'normal',
						}}
					>
						Notification
					</NavLink>
				</div>

				<div
					className={`nav-item ${activeLink === 'Support Requests' ? 'active' : ''}`}
					onClick={() => handleLinkClick('Support Requests')}
					style={{
						backgroundColor: activeLink === 'Support Requests' ? 'white' : 'transparent',
						border: activeLink === 'Support Requests' ? '0.5px solid #FBC49D' : 'initial',
						borderRadius: activeLink === 'Support Requests' ? '8px' : 'initial',
					}}
				>
					<img
						src={activeLink === 'Support Requests' ? activeHelpSupport : helpsupportIcon}
						alt="Support Requests Icon"
					/>
					<NavLink
						className="nav-link"
						to={Pathname.HELPANDSUPPORT}
						style={{
							color: activeLink === 'Support Requests' ? '#F56F10' : 'initial',
							fontWeight: activeLink === 'Support Requests' ? 'bold' : 'normal',
						}}
					>
						Support Requests
					</NavLink>
				</div>
			</nav>
		</div>
	);
};

export default Sidebar;
